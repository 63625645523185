import React, { createContext, useState, useMemo } from 'react';

export const ModalContext = createContext(
	{} as ModalContextProps
);

interface ModalContextProps {
	isBusinessAccountAdOpen: boolean;
	setIsBusinessAccountAdOpen: Function;
}

const ModalProvider: React.FunctionComponent<{}> = ({ children }) => {
	const [isBusinessAccountAdOpen, setIsBusinessAccountAdOpen] = useState(false);
	return (
		<ModalContext.Provider 
			value={{
				isBusinessAccountAdOpen,
				setIsBusinessAccountAdOpen
			}}>
			{children}
		</ModalContext.Provider>
	);
};

export default ModalProvider;
