import React, { Fragment } from 'react';
import { ThemeProvider } from 'theme-ui';
import { Global } from '@emotion/react'
import { Helmet } from 'react-helmet';
import LocalCartProvider from '../../provider/local-cart-provider';
import SearchProvider from '../../provider/search-provider';
import ModalProvider from '../../provider/modal-provider';

import theme from '../../gatsby-plugin-theme-ui';
import styles from './layout.style';

const Layout: React.FunctionComponent = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<LocalCartProvider>
				<SearchProvider>
					<ModalProvider>
						<Fragment>
							<Helmet>
								<meta
									name="viewport"
									content="width=device-width, initial-scale=1 maximum-scale=1"
								/>
							</Helmet>
							<Global styles={styles} />
							{children}
						</Fragment>
					</ModalProvider>
				</SearchProvider>
			</LocalCartProvider>
		</ThemeProvider>
	);
};

export default Layout;
